/* @font-face {
  font-family: 'Raleway';
  src: url(../assets/fonts/Raleway_Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(../assets/fonts/Raleway_Bold.ttf) format('truetype');
  font-weight: bold;
} */

.App{
    font-family: 'Poppins', sans-serif;
}

.form-control.is-valid, .was-validated .form-control:valid{
    border-color: #ced4da !important;
    background-image: none !important;
}


body {
    margin: 0;
    background: '#FCFCFC';
    font-family: 'Poppins', sans-serif;
}

strong {
  font-weight:bold;
}

code {

}

.fadeLeft {
  opacity: 0;
  transform: translate(-10vh, 0vh);
  transition: all 1s;
}

.fadeRight {
  opacity: 0;
  transform: translate(10vh, 0vh);
  transition: all 1s;
}


.visible {
  opacity: 1;
  transform: translate(0, 0);
}
